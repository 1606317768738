import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Home = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      avatar: file(absolutePath: { regex: "/avatar.png/" }) {
        childImageSharp {
          fixed(width: 225, height: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          title
          author {
            name
            bio
          }
          social {
            twitter
            github
          }
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata.title
  const { author } = data.site.siteMetadata

  return (
    <Layout location={location} title={siteTitle} >
      <SEO title="Home" />
      <div
        style={{
          display: `flex`,
          flexDirection: 'column',
          alignItems: `center`,
          justifyContent: `center`,
          margin: 'auto'
        }}
      >
        <div
          className="splash-container"
          style={{
            display: 'flex',
            alignItems: `center`,
            justifyContent: `space-between`,
            width: '100%'
          }}
        >
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt={author.name}
            style={{
              minWidth: '225px'
            }}
          />
          <div style={{ marginLeft: '3em' }}>
            <h2>Hi, I'm Regina <span role="img" aria-label="hello">👋</span></h2>
            <p>
              I'm a software engineer striving to create products that have positive
              social impact.
            </p>
            <p>If you have any questions or just want to say hi, feel free to <a href="mailto:regina.v.lee@gmail.com" target="_blank" rel="noreferrer noopener">
                get in touch!
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home
